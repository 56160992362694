<template>
  <div>
    <el-dialog v-model="deviceVisible" width="70%" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" >
      <template #title>
        <span style="color: white; font-size: 20px;">设备列表</span>
        <el-button size="small" style="margin-left: 5px; float: right" @click="search">搜索</el-button>
        <el-input v-model="condition" placeholder="设备ID" prefix-icon="el-icon-search"  size="small" style="width: 200px; float: right" clearable></el-input>
      </template>

      <el-table :data="dataList" border style="width: 100%; border-radius: 10px" :empty-text="emptyText" >
        <el-table-column prop="deviceId" label="设备ID"  align="center " />
        <el-table-column prop="deviceName" label="设备名称"  align="center " />
        <el-table-column prop="deviceModel" label="设备型号"  align="center " />
        <el-table-column prop="onLine" label="设备状态" align="center" >
          <template #default="scope">
            <span v-if="scope.row.onLine==1" style="color:green">在线</span>
            <span v-if="scope.row.onLine==0" style="color:red">离线</span>
          </template>
        </el-table-column>
        <el-table-column v-if="this.role != 1" prop="elec" label="设备电量"  align="center " >
          <template #default="scope">
            <span v-if="scope.row.elec == 0">--</span>
            <span v-if="scope.row.elec != 0">{{scope.row.elec}}%</span>
          </template>
        </el-table-column>
        <el-table-column v-if="this.role != 1" prop="userName" label="绑定用户"  align="center " />
        <el-table-column label="选择" width="100" align="center ">
          <template #default="scope">
            <el-radio v-model="index" :label="scope.$index" >&nbsp;</el-radio>
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination">
        <ls-pagination :currentPage="currentPage" :page-size="size" :total="total" @size-change="handleSizeChange" @cur-change="handleCurrentChange"></ls-pagination>
      </div>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancel" size="small">取消</el-button>
          <el-button type="primary" @click="sure" size="small">确定</el-button>
        </span>
      </template>
    </el-dialog>

   
  </div>
</template>

<script>
import {getWearInfos,addSgWearInfo} from '../../../api/api'
import session from "../../../store/store";
export default {
  data() {
    return {
      emptyText:'加载中',
      deviceVisible: true,
      condition:'',
      index:-1,
      currentPage: 1,
      size: 10,
      total: 0, //总数
      dataList: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.role = session.getLoginInfo().accountType;
      this.getWearInfos();
    },
    search() {
      this.getWearInfos();
    },
    getWearInfos() {
      let param = {
        unitUuid:session.getLoginInfo().unitUuid,
        role:this.role,
        page:this.currentPage - 1,
        size:this.size,
        deviceId:this.condition,
      }
      getWearInfos(param).then(res => {
        if (res.data.code == 200) {
          this.total = res.data.count;
          this.dataList = res.data.dataInfo;
        }else {
          this.total = 0;
          this.dataList.splice(0, this.dataList.length);
          this.emptyText = "暂无数据"
        }
      })
    },
    sure() {
      if(this.index == -1) {
        this.$message.warning({ message: "请先选择用户"});
        return;
      }
      var data = this.dataList[this.index]
      let param = {
        unitUuid:session.getLoginInfo().unitUuid,
        deviceId:data.deviceId,
      }
      addSgWearInfo(param).then(res => {
        if (res.data.code == 200) {
          this.$message({ message: '新增成功',  type: 'success'});
          this.cancel();
          this.update();
        } else {
          this.$message.error({ message: res.data.message});
        }
      })
    },

    cancel() {
      this.userVisible = false
      this.$emit("cancel");
    },
    update() {
      this.$emit("update");
    },
      //分页
    handleSizeChange(val) {
      this.size = val;
      this.getWearInfos();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getWearInfos();
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  text-align: center;
  margin: 20px 0px;
}

// .input {
//   width: 50%;
// }
hr {
  border-color: #f4f4f526;
}
.page {
  padding: 0px 20px;
}
.el-form-item {
  margin-bottom: 0px !important;
  margin-left: -20px;
}
.row {
  margin: 20px 0px 20px 0px;
}
.box {
  padding: 20px;
  width: 75%;
  background-color: white;
}
.title {
  margin-right: 25px;
  font-size: 16px;
}

.content {
  font-size: 16px;
  margin-left: 8px;
}
</style>